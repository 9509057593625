import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

function Idling() {
    const [unitGroups, setUnitGroups] = useState([]);
    const [selectedUnitGroup, setSelectedUnitGroup] = useState('');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [vehicleData, setVehicleData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setDefaultDateTimes();
        loadUnitGroups();
    }, []);

    const setDefaultDateTimes = () => {
        const now = new Date();
        const year = now.getFullYear();
        const month = String(now.getMonth() + 1).padStart(2, '0');
        const day = String(now.getDate()).padStart(2, '0');

        // Set start time to 00:00 and end time to 23:59
        const startDateTime = `${year}-${month}-${day}T00:00`;
        const endDateTime = `${year}-${month}-${day}T23:59`;

        setStartDate(startDateTime);
        setEndDate(endDateTime);
    };

    const loadUnitGroups = async () => {
        try {
            const response = await fetch('http://localhost:8000/get-unit-groups_idling');
            if (!response.ok) {
                throw new Error(`Failed to fetch unit groups: ${response.statusText}`);
            }
            const data = await response.json();
            setUnitGroups(data);
        } catch (error) {
            console.error('Error loading unit groups:', error);
            alert('Failed to load unit groups.');
        }
    };

    const fetchData = async () => {
        if (!selectedUnitGroup || !startDate || !endDate) {
            alert('Please fill in all fields.');
            return;
        }

        setLoading(true);

        const requestUrl = `http://localhost:8000/map-rows-and-subrows_idling/?unitGroup=${selectedUnitGroup}&startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`;
        try {
            const response = await fetch(requestUrl);
            if (!response.ok) {
                throw new Error(`Failed to fetch data: ${response.statusText}`);
            }

            const data = await response.json();
            setVehicleData(data.map((row, index) => ({ id: index + 1, ...row }))); // Add auto-number
            if (data.length === 0) {
                alert('No data found for the selected parameters.');
            }
        } catch (error) {
            alert('An error occurred while fetching data. Please try again.');
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };


    const exportToExcel = async () => {
        try {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Summaries Idling Report 5 Minutes');
        const headers = [
            'No',
            'Plate Number',
            'Division',
            'Model',
            'Department',
            'Term',
            'User',
            'Fuel',
            '>= 5mn To <10 mn',
            '>= 10mn To <15 mn',
            '15mn Up',
            'Total Idling',
            'Idling Total Minute(>= 5mn To <10 mn)',
            'Idling Total Minute(>= 10mn To <15 mn)',
            'Idling Total Minute(15mn Up)',
            'Idling Total Minute',
            'Idling Total Hour',
            'Idling Violation',
            'Idling Non-Violation',
            'Working Hour',
            'Non-Working Hour'
        ];

        function convertToMinutes(timeString) {
            if (!timeString || !timeString.includes(":")) {
                return 0; // Handle cases where timeString is empty, null, or does not contain ":"
            }
            let timeParts = timeString.split(":");
            return parseInt(timeParts[0]) * 60 + parseInt(timeParts[1]);
        }
        
        function convertMinutesToHoursMinutes(totalMinutes) {
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            return `${hours} H ${minutes} M`;
        }

        worksheet.getCell('A6').value = 'Summaries Idling Report 5 Minutes';
        worksheet.getCell('A6').font = { size: 16, bold: true };
        worksheet.getCell('A6').alignment = { vertical: 'middle', horizontal: 'left' };            
        worksheet.getCell('A7').value = 'Date:';
        worksheet.getCell('B7').value = `From: ${new Date(startDate).toLocaleDateString()}`;
        worksheet.getCell('C7').value = `To: ${new Date(endDate).toLocaleDateString()}`;
        worksheet.addRow([]);
        worksheet.addRow(headers);
           
        vehicleData.forEach((row, index) => {
            worksheet.addRow([
            index + 1, // Auto-number
            row.plateNumber,
            row.division,
            row.model,
            row.department,
            row.term,
            row.user,
            row.fuel == "-----" ? 0 : row.fuel,
            Number(row.idling59),
            Number(row.idling1015),
            Number(row.idling15),
            Number(row.idling59) + Number(row.idling1015) + Number(row.idling15), // Sum idle times
            convertToMinutes(row.idling59min) + " mm", // Convert idling59min to minutes
            convertToMinutes(row.idling1015min) + " mm", // Convert idling1015min to minutes
            convertToMinutes(row.idling15min) + " mm", // Convert idling15min to minutes
            convertToMinutes(row.idling59min) + convertToMinutes(row.idling1015min) + convertToMinutes(row.idling15min) + " mm", // Total minutes
            convertMinutesToHoursMinutes(convertToMinutes(row.idling59min) + convertToMinutes(row.idling1015min) + convertToMinutes(row.idling15min)),
            (Number(row.idling59) + Number(row.idling1015) + Number(row.idling15)) - (Number(row.nighttimes) + Number(row.saturdaytime) + Number(row.sundaytime)),
            (Number(row.nighttimes) + Number(row.saturdaytime) + Number(row.sundaytime)),
            convertMinutesToHoursMinutes((convertToMinutes(row.idling59min) + convertToMinutes(row.idling1015min) + convertToMinutes(row.idling15min)) - (convertToMinutes(row.nighttimesmin) + convertToMinutes(row.saturdaytimemin) + convertToMinutes(row.sundaytimemin))),
            convertMinutesToHoursMinutes(convertToMinutes(row.nighttimesmin) + convertToMinutes(row.saturdaytimemin) + convertToMinutes(row.sundaytimemin)),
        ]);
    });
        const imageResponse = await fetch(`${process.env.PUBLIC_URL}/logo/HIL.png`);
            const imageBuffer = await imageResponse.arrayBuffer();
            const imageId = workbook.addImage({
                buffer: imageBuffer,
                extension: 'png',
            });
    
            worksheet.addImage(imageId, {
                tl: { col: 0, row: 0 }, // Top-left corner (A1)
                ext: { width: 300, height: 100 } // Image size
            });
            

            worksheet.getColumn(1).width = 5; // Set width of 'No' column to 10
            worksheet.columns.forEach(column => {
                if (column.number !== 1) { // Exclude the 'No' column
                    const maxLength = column.values.reduce((max, curr) => Math.max(max, curr?.toString().length || 0), 0);
                    column.width = Math.max(maxLength + 1, 2); // Ensure width is at least 10
                }
                column.alignment = { horizontal: 'left' };
            });
    
            const buffer = await workbook.xlsx.writeBuffer();
            saveAs(new Blob([buffer]), 'Summaries Idling Report 5 Minutes.xlsx');
    
        } catch (error) {
            console.error('Error exporting to Excel:', error);
        }
    };
    const timeToMinutes = timeString => {
        if (!timeString) return 0; // Handle empty or undefined input
        const timeParts = timeString.split(':'); // Split the string by ':'
    
        if (timeParts.length !== 3) return 0;
      
        const hours = Number(timeParts[0]);
        const minutes = Number(timeParts[1]);
        const seconds = Number(timeParts[2]);
      
        // Convert hours and seconds to minutes and return the total minutes
        return (hours * 60) + minutes + Math.floor(seconds / 60);
      };
      const TotalIdling= row=> Number(row.idling59) + Number(row.idling1015) + Number(row.idling15); 
      const TotalNonViolation = row=>  Number(row.nighttimes) + Number(row.saturdaytime) + Number(row.sundaytime);
      const TotalViloation = row => Number(TotalIdling(row)) - Number(TotalNonViolation(row));

    const columns = [
        
        { name: 'No', selector: row => row.id, sortable: true }, // Auto-number column
        { name: 'Division', selector: row => row.division, sortable: true },
        { name: 'Plate Number', selector: row => row.plateNumber, sortable: true },
        { name: 'Model', selector: row => row.model, sortable: true },
        { name: 'Department', selector: row => row.department, sortable: true },
        { name: 'Term', selector: row => row.term, sortable: true },
        { name: 'User', selector: row => row.user, sortable: true },
        { name: 'Fuel', selector: row => row.fuel, sortable: true },
        { name: '>= 5mn To <10 mn', selector: row => row.idling59, sortable: true },
        { name: '>>= 10mn To <15 mn', selector: row => row.idling1015, sortable: true },
        { name: '15mn Up', selector: row => row.idling15, sortable: true },
        { name: 'Total Idling', selector: TotalIdling
        , sortable: true },
        // { name: 'Total Idling', selector:row => (Number(row.idling59) || 0) + (Number(row.idling1015) || 0) + (Number(row.idling15) || 0), sortable: true },
        { name: 'Idling Total Minute(>= 5mn To <10 mn)', selector: row => 
        {
            const idling59min = row.idling59min ? timeToMinutes(row.idling59min) : 0;
            return `${idling59min} mm`; // Add "mm" to the total minutes
        },sortable: true },
        { name: 'Idling Total Minute(>= 10mn To <15 mn)', selector: row => 
            {
                const idling1015min = row.idling1015min ? timeToMinutes(row.idling1015min) : 0;
                return `${idling1015min} mm`; // Add "mm" to the total minutes
            },sortable: true },
        { name: 'Idling Total Minute(15mn Up)', selector: row =>
            {
                const idling15min = row.idling15min ? timeToMinutes(row.idling15min) : 0;
                return `${idling15min} mm`; // Add "mm" to the total minutes
            },sortable: true },
        { name: 'Idling Total Minute', selector: row => {
        const timeToMinutes = timeString => {
            const [hours, minutes, seconds] = timeString.split(':').map(Number);
            return Math.floor((hours * 60) + minutes + (seconds / 60));
          };
          
          const idling59min = row.idling59min ? timeToMinutes(row.idling59min) : 0;
          const idling1015min = row.idling1015min ? timeToMinutes(row.idling1015min) : 0;
          const idling15min = row.idling15min ? timeToMinutes(row.idling15min) : 0;
          const totalMinutes = idling59min + idling1015min + idling15min;
          return `${totalMinutes} mm`;
          }, sortable: true },
        { name: 'Idling Total Hour', selector: row => {
        
            const timeToMinutes = timeString => {
                const [hours, minutes, seconds] = timeString.split(':').map(Number);
                return Math.floor((hours * 60) + minutes + (seconds / 60));
              };
              
              const idling59min = row.idling59min ? timeToMinutes(row.idling59min) : 0;
              const idling1015min = row.idling1015min ? timeToMinutes(row.idling1015min) : 0;
              const idling15min = row.idling15min ? timeToMinutes(row.idling15min) : 0;
              
              // Calculate total minutes
              const totalMinutes = idling59min + idling1015min + idling15min;
              
              // Convert total minutes to hours and minutes
              const hours = Math.floor(totalMinutes / 60);
              const minutes = totalMinutes % 60;
              
              // Return the result in hours and minutes format
              return `${hours} H ${minutes} M`;
        }, sortable: true },
        { name: 'Idling Violation', selector: TotalViloation, sortable: true },
        { name: 'Idling Non-Violation', selector: TotalNonViolation, sortable: true },
        { name: 'Working Hour', selector:  row=>{
            const timeToMinutesVialotion = timeString => {
                const [hours, minutes, seconds] = timeString.split(':').map(Number);
                return Math.floor((hours * 60) + minutes + (seconds / 60));
              };
              const idling59min = row.idling59min ? timeToMinutes(row.idling59min) : 0;
              const idling1015min = row.idling1015min ? timeToMinutes(row.idling1015min) : 0;
              const idling15min = row.idling15min ? timeToMinutes(row.idling15min) : 0;
              const nighttimesmin = row.nighttimesmin ? timeToMinutesVialotion(row.nighttimesmin) : 0;
              const saturdaytimemin = row.saturdaytimemin ? timeToMinutesVialotion(row.saturdaytimemin) : 0;
              const sundaytimemin = row.sundaytimemin ? timeToMinutesVialotion(row.sundaytimemin) : 0;
              const totalMinutesNon = (idling59min + idling1015min + idling15min) - (nighttimesmin + saturdaytimemin + sundaytimemin);
              const whours = Math.floor(totalMinutesNon/ 60);
              const wminutes = totalMinutesNon% 60;
              return `${whours} H ${wminutes} M`;

        }, sortable: true },

        { name: 'Non Working Hour', selector:  row=>{
            const timeToMinutesVialotion = timeString => {
                const [hours, minutes, seconds] = timeString.split(':').map(Number);
                return Math.floor((hours * 60) + minutes + (seconds / 60));
              };
              
              const nighttimesmin = row.nighttimesmin ? timeToMinutesVialotion(row.nighttimesmin) : 0;
              const saturdaytimemin = row.saturdaytimemin ? timeToMinutesVialotion(row.saturdaytimemin) : 0;
              const sundaytimemin = row.sundaytimemin ? timeToMinutesVialotion(row.sundaytimemin) : 0;
              
              // Calculate total minutes
              const totalMinutes = nighttimesmin + saturdaytimemin + sundaytimemin;
              
              // Convert total minutes to hours and minutes
              const whours = Math.floor(totalMinutes / 60);
              const wminutes = totalMinutes % 60;
              return `${whours} H ${wminutes} M`;
            

        }, sortable: true },
        // { name: 'Non-Working Hour', selector: test, sortable: true },
    ];

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">Idling Summaries Report</h1>
                        </div>
                    </div>
                </div>
            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="controls" style={{ marginBottom: '20px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'flex-start', gap: '20px' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <label htmlFor="unitGroup" style={{ marginRight: '10px', fontWeight: 'bold' , fontSize:'12px'  }}>Unit Group:</label>
                                <select id="unitGroup" value={selectedUnitGroup} onChange={e => setSelectedUnitGroup(e.target.value)} style={{ padding: '5px', fontSize: '12px', minWidth: '150px' }}>
                                    <option value="">Select a group...</option>
                                    {unitGroups.map(group => (
                                        <option key={group.id} value={group.id}>{group.nm}</option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <label htmlFor="startDate" style={{ marginRight: '10px', fontWeight: 'bold', fontSize:'12px' }}>Start Date:</label>
                                <input type="datetime-local" id="startDate" value={startDate} onChange={e => setStartDate(e.target.value)} style={{ padding: '5px', fontSize: '12px', minWidth: '150px' }} />
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <label htmlFor="endDate" style={{ marginRight: '10px', fontWeight: 'bold' , fontSize:'12px'  }}>End Date:</label>
                                <input type="datetime-local" id="endDate" value={endDate} onChange={e => setEndDate(e.target.value)} style={{ padding: '5px', fontSize: '12px', minWidth: '150px' }} />
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <button onClick={fetchData} style={{ padding: '5px 15px', fontSize: '12px' }}>Search</button>
                            </div>

                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <button onClick={exportToExcel} style={{padding: '5px 15px', fontSize: '12px' }}>Export to Excel</button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <DataTable
                            columns={columns}
                            data={vehicleData}
                            progressPending={loading}
                            pagination
                            highlightOnHover
                            noHeader={true}  // Remove header
                        />
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Idling;
