import React from "react";
import { Link } from "react-router-dom";
function Navigation() {
  const role = localStorage.getItem('role');
  return (
    <div>
      <aside className="main-sidebar sidebar-dark-primary elevation-4">
        {/* Brand Logo */}
        <a className="brand-link">
          <span className="brand-text font-weight-light">
          Mango Tracking Co., LTD
            {/* <button onClick={handleLogout} className="btn btn-danger ml-2">
              Sign Out
            </button> */}
          </span>
        </a>
        {/* Sidebar */}
        <div className="sidebar">
          {/* Sidebar Menu */}
          <nav className="mt-2">
            <ul
              className="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              {role === '1' && (
                <>
                  <li className="nav-header">Master Report</li>
                  <li className="nav-item">
                  <Link to="IVMS" className="nav-link"> {/* Use Link with to="/IVMS" */}
                      <i className="fas fa-circle nav-icon"></i>
                      <p>IVMS Report</p>
                    </Link>
                  </li>
                </>
              )}

              {role === '2' && (
                <>
                  <li className="nav-header">RMA Report</li>
                  <li className="nav-item">
                    <a href="" className="nav-link">
                      <i className="nav-icon fas fa-circle"></i>
                      <p>
                        RMA Report
                        <i className="right fas fa-angle-left"></i>
                      </p>
                    </a>
                    <ul className="nav nav-treeview">
                      <li className="nav-item">
                        <Link to="overspeed" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>OverSpeed Report</p>
                        </Link>
                      </li>
                      
                      <li className="nav-item">
                      <Link to="idling" className="nav-link">
                          <i className="far fa-circle nav-icon"></i>
                          <p>Idling Report</p>
                        </Link>
                      
                      </li>
                      
                     
                    </ul>
                  </li>
                  
                </>
              )}
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
}

export default Navigation;
